import React, { useContext, useEffect, useState, useLayoutEffect } from "react";

import MenuContext from "../../contexts/menu";
import AuthContext from "../../contexts/auth";
import {
  Container,
  CompanyContainer,
  LogoContainer,
  CompanyName,
  MenuList,
  Version
} from "./styles"


import MenuItem from "../menuitem";
import aux from "../../utils/auxiliar";
import storage from "../../storage"
import packageInfo from "../../../package.json";

const { name, version } = packageInfo;

type TMenuItens = {
  id: number;
  label: string;
  link: string;
}


/**
 * 
 * 19334    Rondas
19335    Faturas e Boletos
19336    Ponto
19337    Funcionários
19338    Documentos
19339    Quadro de Vagas
 */

let allMenus = [
  { id: 0, label: 'Home', link: '/', idSar: 0 },
  { id: 1, label: 'Rondas', link: 'rondas', idSar: 19334 },
  { id: 2, label: 'Usuarios', link: 'usuarios', idSar: 0 },
  { id: 3, label: 'Faturas e Boletos', link: 'financeiro', idSar: 19335 },
  { id: 4, label: 'Ponto', link: 'ponto', idSar: 19336 },
  { id: 5, label: 'Funcionários', link: 'funcionarios', idSar: 19337 },
  { id: 6, label: 'Documentos', link: 'documentos', idSar: 19338 },
  { id: 7, label: 'Quadro de Vagas', link: 'quadro', idSar: 19339 }
]

const Aside: React.FC = ({ children }) => {
  const { isOpen, currentPage, navigateTo } = useContext(MenuContext);
  const { loggedEmail, isMasterUser, acessos, validaAcessos } = useContext(AuthContext)
  const [group, setGroup] = useState<string>("");
  const [logo, setLogo] = useState<string | null>(null);
  const [menuItems, setMenuItems] = useState<TMenuItens[]>([]);

  const _isMaster = isMasterUser();

  useLayoutEffect(() => {
    buildMenu();
  }, [validaAcessos])



  useEffect(() => {
    const group = storage.getGroupLogged();
    if (group)
      setGroup(group)
    const _logo = storage.getLogoGroupLogged();

    if (_logo && _logo !== "null") {
      setLogo(_logo)
    }
  }, [])



  function buildMenu() {
    if (!validaAcessos) {
      setMenuItems(allMenus)
    } else {

      let aux: any = [];

      aux.push(...allMenus.filter(i => i.idSar === 0));

      for (const acesso of acessos) {
        const acIndex = allMenus.findIndex(i => i.idSar === acesso);
        if (acIndex !== -1) {
          aux.push(allMenus[acIndex])
        }
      }
      if (aux.length > 0)
        setMenuItems(aux)
    }
  }



  return (
    <Container>
      <CompanyContainer>
        {logo && <LogoContainer>
          <img src={logo} alt="logo" />
        </LogoContainer>}
        <CompanyName>{group}</CompanyName>
        <span>{loggedEmail}</span>
        <span>Portal do Cliente</span>
      </CompanyContainer>
      <MenuList>
        {menuItems.length > 0 && menuItems.map((item, index) => {
          if (item.id === 2) {
            if (!_isMaster) return null;
          }
          return (
            <li key={index}>
              <MenuItem
                label={item.label}
                link={item.link}
                Icon={aux.resolveMenuIcon(item.id)}
                isActive={currentPage === item.link}
              />
            </li>
          )
        })}
      </MenuList>
      <Version>v{version}</Version>
    </Container>
  )
}

export default Aside;



