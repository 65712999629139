import { useContext, useEffect, useState } from "react";
import { BodyProdutosRequest, ILocal } from "../../@interfaces";
import { LocalProduto, Posicao, Produto, SelectOptions } from "../../@types";
import { CustumerContext } from "../../contexts/custumer";
import ToastContext from "../../contexts/toast";
import APIPonto from "../../services/APIPonto";
import APIQuadroVagas from "../../services/APIQuadroVagas";
import aux from "../../utils/auxiliar";
import moment from "moment";


interface IFiltros {
  local?: string | number,
  ordem?: string | number,
  dataInicio?: string,
  dataFinal?: string
}


export const useQuadroDeVagas = () => {
  const opcoesOrdem: SelectOptions[] = [
    { value: 'data', label: 'Data'},
    { value: 'vaga', label: 'Vaga'}
  ]

  const filtrosPadrao = {ordem: 'data', dataInicio: "", dataFinal: "" };

  const [quadro, setQuadro] = useState<LocalProduto[]>();

  const [locais, setLocais] = useState<SelectOptions[]>();
  const [opcaoDeFiltroAberta, setOpcaoDeFiltroAberta] = useState<"local"|"ordem"|null>();
  const [filtros, setFiltros] = useState<IFiltros>(filtrosPadrao);
  const [dataInvalida, setDataInvalida] = useState<boolean>(false);

  const [carregandoQuadro, setCarregandoQuadro] = useState<boolean>(false);
  const [carregandoLocais, setCarregandoLocais] = useState<boolean>(false);

  const { id_current_custumer } = useContext(CustumerContext);
  const { showErrorToast } = useContext(ToastContext);



  useEffect(() => {
    carregarLocais();
  }, [])


  useEffect(() => {
    limparQuadro();
  }, [id_current_custumer]);




  async function carregarLocais(){
    try{
      setCarregandoLocais(true);

      const resposta = await APIPonto.getPlacesList({ cliente: id_current_custumer, SomentePontoEletronico: false })

      if(resposta.error){ 
        setQuadro([]);
        showErrorToast(resposta.message)
        return;
      }
      const opcoesFormatadas = formatarOpcoes(resposta.local!) 
      setLocais(opcoesFormatadas);
      
      setFiltros({...filtrosPadrao, local: opcoesFormatadas.length === 1 ? opcoesFormatadas[0].value : 0 })
      
    
    }catch{
      return showErrorToast("Erro ao carregar os locais");
    }finally{
      setCarregandoLocais(false);
    }
  }




  async function carregarQuadro(){
    try{
      const validacaoPeriodo = validarData();

      if(validacaoPeriodo.erro){
        showErrorToast(validacaoPeriodo.mensagem!);
        setDataInvalida(true);
        return;
      }

      setCarregandoQuadro(true);

      const body: BodyProdutosRequest = {
        ...filtros,
        dataFinal: aux.formatDateToString(filtros.dataFinal!) + "T00:00",
        dataInicio: aux.formatDateToString(filtros.dataInicio!) + "T00:00",
        cliente: id_current_custumer,
      }

      const resposta = await APIQuadroVagas.getProdutos(body);

      if(resposta.error) return showErrorToast(resposta.message);
      
      setQuadro(resposta.locais);

    }catch{
      return showErrorToast("Erro ao carregar quadro de vagas");
    }finally{
      setCarregandoQuadro(false);
    }
  }




  async function limparQuadro() {
    carregarLocais();
    setQuadro([]);
  }




  function formatarOpcoes(list: ILocal[]){
		const arrayFormatada = list.map(item => {
			return {
				value: item.local,
				label: item.descricao
			}
		})

		return arrayFormatada.length > 1 ? [...arrayFormatada, { value: 0, label: 'Todos'}] : arrayFormatada;
	}




  function abrirDropdown(filtro: "local"|"ordem"){
    setOpcaoDeFiltroAberta(filtro);
  }




  function fecharDropdown(){
    setOpcaoDeFiltroAberta(null);
  }




  function selecionarFiltro(filtro: keyof IFiltros, valor: string | number){

    if(filtro === 'dataFinal' || filtro === 'dataInicio') setDataInvalida(false);
    

    setFiltros({...filtros!, [filtro]: valor});
  }




  function validarData(){
    const dataInicio = moment(filtros.dataInicio);
    const dataFinal = moment(filtros.dataFinal);  
    const diferencaEmDias = dataFinal.diff(dataInicio, 'days');

    if(diferencaEmDias > 30) return { erro: true, mensagem: 'O período deve ter, no máximo, 30 dias.'}
    if(diferencaEmDias < 0) return { erro: true, mensagem: 'Data inicial posterior à data final'};

    return { erro: false };
  
  }




  

  return {
    quadro,
    locais,
    filtros,
    opcaoDeFiltroAberta,
    carregandoQuadro,
    carregandoLocais,
    carregarQuadro,
    abrirDropdown,
    fecharDropdown,
    selecionarFiltro,
    opcoesOrdem,
    dataInvalida
  }
}