import React from 'react';

import { Container, Tip } from './styles';

interface IProps {
  texto: string;
  direcao?: 'direita' | 'esquerda';
  children: JSX.Element;
}

const ToolTip: React.FC<IProps> = ({ children, texto, direcao }) => {
  return (
    <Container aDireita={direcao === "direita"}>
      {children}
      <div>
        <Tip>{texto}</Tip>
      </div>
    </Container>
  )
}

export default ToolTip;