import { BodyBoletoDownloadRequest, BodyBoletosRequest, BodyFaturasRequest, IResponseBoletos, IResponseBoletosDownload, IResponseFaturas } from "../@interfaces";
import storage from "../storage";
import Api from "./APIAxios";

const APIFinanceiro = {
  getFaturas: async function (body: BodyFaturasRequest): Promise<IResponseFaturas> {
    try{
      const url = storage.getClientUrl();
      const response = await Api.post<IResponseFaturas>(`${url}/faturas`, body);

      if (response?.data?.status === 0 || response?.data?.status === 126 || response?.data?.status === 129) return response.data;
       
      return { error: true, message: response.data?.mensagem ? response?.data?.mensagem : "Erro Desconhecido" };
    }catch(error){
      return { error: true, message: error };
    }
  },

  
  
  
  getBoletos: async function (body: BodyBoletosRequest): Promise<IResponseBoletos> {
    try{
      const url = storage.getClientUrl();
      const response = await Api.post<IResponseBoletos>(`${url}/boletos`, body);
      if (response?.data?.status === 0 || response?.data?.status === 126 || response?.data?.status === 128 || response?.data?.status === 132) return response.data;

      return { error: true, message: response.data?.mensagem ? response?.data?.mensagem : "Erro Desconhecido" };
    }catch(error){
      return { error: true, message: error };
    }
  },

  
  
  getArquivoDownload: async function (body: BodyBoletoDownloadRequest): Promise<IResponseBoletosDownload> {
    try{
      const url = storage.getClientUrl();
      const response = await Api.post<IResponseBoletos>(`${url}/RecuperaArquivo`, body);
      if (response?.data?.status === 0 || response?.data?.status === 126) return response.data;

      return { error: true, message: response.data?.mensagem ? response?.data?.mensagem : "Erro Desconhecido" };
    }catch(error){
      return { error: true, message: error };
    }
  }


}

export default APIFinanceiro;