import React, { useContext, useState } from "react";
import { BsDownload, BsBoxArrowUpRight } from "react-icons/bs";
import { IRows } from "../../../../@interfaces";
import { Boleto, Fatura } from "../../../../@types";
import EmptyListMessage from "../../../../components/mensagemlistavazia";
import Loading from "../../../../components/loading";
import Table from "../../../../components/table"
import Title from "../../../../components/title";
import { CustumerContext } from "../../../../contexts/custumer";
import ToastContext from "../../../../contexts/toast";
import APIFinanceiro from "../../../../services/APIFinanceiro";
import aux from "../../../../utils/auxiliar";

import {
  Container,
  NotaNumber,
  ColumnBox,
  RowBox,
  Section,
  Grid,
  IconOpen,
  IconClose,
  LabelBoleto,
  OpcoesContainer,
} from "./styles"
import ToolTip from "../../../../components/tooltip";
import { useTheme } from "styled-components";

interface IProps {
  isOpen?: boolean
  data: Fatura,
  getFile: (arquivoId: number) => Promise<{ base64?: string, errorMessage?: string }>,
  showDoc: (base64: string) => void
}

const _columns = [
  {
    fieldName: "NF"
  },
  {
    fieldName: "Val. Bruto",
    alignRight: true
  },
  {
    fieldName: "Val. Liquido",
    alignRight: true
  },
  {
    fieldName: "Banco"
  },
  {
    fieldName: "Venc. Boleto"
  },
  {
    fieldName: "Val. Boleto",
    alignRight: true
  },

]






const CardFatura: React.FC<IProps> = ({ isOpen, data, getFile, showDoc }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [dataRow, setDataRow] = useState<IRows[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { showErrorToast } = useContext(ToastContext);

  const { id_current_custumer } = useContext(CustumerContext);

  const theme = useTheme();

  async function loadBoletosRelacionados() {

    document.getElementById("content")?.scrollTo(0, 0);

    try {
      setIsLoading(true);
      const body = {
        dataInicial: '',
        dataFinal: '',
        nf: parseInt(data.numeroNF),
        cliente: id_current_custumer
      }

      const response = await APIFinanceiro.getBoletos(body);

      if (response.boletos) {
        const sortedList = sortBoletos(response.boletos);
        const rows = formatRows(sortedList);
        setDataRow(rows);
      } else {
        setDataRow([])
        showErrorToast(response.message)
      }

    } catch {
      showErrorToast('Algo deu errado no carregamento dos boletos')
    } finally {
      setIsLoading(false);
    }
  }

  function sortBoletos(list: Boleto[]) {
    const sortedList = list.sort((a, b) => {
      const splittedA = a.dtEmimssao.split('/', 3);
      const splittedB = b.dtEmimssao.split('/', 3);
      const dateA = new Date(parseInt(splittedA[2]), parseInt(splittedA[1]), parseInt(splittedA[0]));
      const dateB = new Date(parseInt(splittedB[2]), parseInt(splittedB[1]), parseInt(splittedB[0]));

      return Number(dateB) - Number(dateA);
    })

    return sortedList;
  }


  function formatRows(list: Boleto[]) {
    const rows = list.map(i => {
      return {
        "id": i.contasAReceber,
        "NF": i.nF,
        "Val. Bruto": aux.maskReal(i.valBruto),
        "Val. Liquido": aux.maskReal(i.vaLiquido),
        "Banco": i.banco,
        "Venc. Boleto": i.vencBoleto,
        "Val. Boleto": aux.maskReal(i.valBoleto),
        timestamp: i.dtEmimssao,
        arquivo: i.arquivo,
        actionButtons: [{
          icon: <BsDownload size={16} />,
          indexAction: 0
        },
        {
          icon: <BsBoxArrowUpRight size={16} />,
          indexAction: 1
        }
        ]
      }
    })

    return rows;
  }



  async function handleBoletoDownload(id: number) {
    const item = dataRow.find(i => i.id === id);
    const file = await getFile(parseInt(item!.arquivo));
    const name = item!.timestamp;
    const splitted = name.split(" ");
    const formatted = `${aux.parseToYYYYMMDD(splitted[0])} ${splitted[1]}`;

    if (file.base64) aux.downloadFile(file.base64, `${formatted}`);
    else if (file.errorMessage) showErrorToast(file.errorMessage);

  }


  async function abrirBoleto(id: number) { 
    const item = dataRow.find(i => i.id === id);
    const file = await getFile(parseInt(item!.arquivo));
    if (file.base64) showDoc(file.base64);
    else if (file.errorMessage) showErrorToast(file.errorMessage);
  }

  

  async function abrirNotaDebito(nota: number){
    const file = await getFile(nota);
    if (file.base64) showDoc(file.base64);
    else if (file.errorMessage) showErrorToast(file.errorMessage);
  }



  function toggleOpen() {
    if (!open) loadBoletosRelacionados();
    setOpen(old => !old);
  }



  return (
    <Container isOpen={open}>
      <RowBox>
        {/* <NotaNumber>
          <span>{data.numeroNF}</span>
        </NotaNumber> */}

        <Grid lastColumnSize={open ? 50 : 145}>
          <ColumnBox>
            <h2 className="title">Empresa</h2>
            <span className="value">{data.descEmpresa}</span>
          </ColumnBox>

          <ColumnBox>
            <h2 className="title">Nota fiscal</h2>
            {data.linkPrefeitura && <a target="_blank" className="value_link" href={`${data.linkPrefeitura}`}>{data.numeroNF}</a>}
            {!data.linkPrefeitura && <span className="value">{data.numeroNF}</span>}
          </ColumnBox>

          <ColumnBox>
            <h2 className="title">Emissão</h2>
            <span className="value">{data.dtEmissao.split(' ')[0]}</span>
          </ColumnBox>

          <ColumnBox>
            <h2 className="title">Venc.</h2>
            <span className="value">{data.dtVencimento}</span>
          </ColumnBox>

          <ColumnBox>
            <h2 className="title">Val. Bruto</h2>
            <span className="value right">{`R$ ${data.valBruto}`}</span>
          </ColumnBox>

          <ColumnBox>
            <h2 className="title">Val. Líquido</h2>
            <span className="value value-right">{`R$ ${data.valorLiquido}`}</span>
          </ColumnBox>


          <OpcoesContainer>
            
            {!open && (data.temBoleto ?
              <LabelBoleto onClick={toggleOpen} isActive>Abrir boletos</LabelBoleto> :
              <LabelBoleto>Fatura sem boleto</LabelBoleto>
            )}

            {!!data.arquivoNotaDebito && 
              <ToolTip texto="Visualizar nota" direcao="direita">
                <a onClick={() => abrirNotaDebito(data.arquivoNotaDebito)}>
                  <BsBoxArrowUpRight size={16} color={theme.colors.black}/>
                </a>
              </ToolTip>
            }

            {open && <IconClose onClick={toggleOpen} />}
          </OpcoesContainer>
        </Grid>
      </RowBox>

      {
        open ? (
          <Section>
            <Title style={{ marginBottom: 20 }}>Boletos Relacionados</Title>
            {dataRow.length > 0 && !isLoading &&
              <Table
                columns={_columns}
                rows={dataRow}
                refAction="id"
                actions={[handleBoletoDownload, abrirBoleto]}
              />
            }

            {dataRow.length === 0 && !isLoading &&
              <EmptyListMessage texto="Não há boletos relacionados" />

            }

            {isLoading && <Loading />}

          </Section>
        ) : null
      }
    </Container>
  )
}

export default CardFatura;