import styled from "styled-components";

interface ITabNameProps {
  active?: boolean;
  onPress?: () => void
}

export const Container = styled.div`
  flex: 1;
  flex-direction: row;
`

export const TabList = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(0,0,0,.1);
  display: flex;
  margin-bottom: 20px;
  max-width: 100%;
`

export const TabName = styled.div<ITabNameProps>`
  transform: translateY(1px);
  transition: all .7s;
  cursor: pointer;
  margin-right: 10px;  
  padding: 8px; 
  border-top-left-radius: 4px; 
  border-top-right-radius: 4px;   
  border-bottom: 2px solid rgba(0,0,0,0);
  

 ${({ active, theme }) => {
    if (active) {
      return `border-bottom: 2px solid ${theme.colors.primary};`
    }
  }}

>h2{
    font-size: 1rem;
    font-weight: 300;
    ${({ active, theme }) => {
    if (active) {
      return `color: ${theme.colors.primary};`
    }
  }}
  }
  &:hover{
    background: rgba(0, 0, 0, .1);
  }
`