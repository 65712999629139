import { MdDownload, MdOpenInNew } from "react-icons/md"
import Loading from "../loading"
import { CheckLabel, CheckOption, LabelContainer, OptionsContainer } from "../../pages/funcionarios/employeeDetail/docs/styles"
import { IDocumento } from "../../@interfaces"
import { useTheme } from "styled-components"
import { Container } from "./styles"


interface IProps {
  documento: IDocumento,
  documentosSelecionados: number[],
  selecionarDocumento: (id: number, checado: boolean) => void,
  acaoCarregando: string | null,
  baixarArquivo: (id: number) => void,
  abrirDocumento: (id: number) => void
}


const DocItem: React.FC<IProps> = ({
  documento,
  documentosSelecionados,
  selecionarDocumento,
  acaoCarregando,
  baixarArquivo,
  abrirDocumento
}) => {

  const { colors } = useTheme();

  return (
    <Container key={documento.arquivo}>
      <CheckOption>
        <input
          type={"checkbox"}
          id={String(documento.arquivo)}
          checked={documentosSelecionados?.includes(documento.arquivo)}
          onChange={event => selecionarDocumento(documento.arquivo, event.target.checked)}
          onClick={e => e.stopPropagation()}
        />
        <LabelContainer>
          <CheckLabel htmlFor={String(documento.arquivo)}><a>{documento.descricaoArquivo} {documento.nomeEmpresa ? `(${documento.nomeEmpresa})` : ""}</a></CheckLabel>
        </LabelContainer>
      </CheckOption>

      <OptionsContainer>
        {acaoCarregando === String(documento.arquivo) ?
          <Loading width={20} noPadding compact /> :
          <a onClick={e => baixarArquivo(documento.arquivo)}>
            <MdDownload
              color={colors.halfOpacityBlack}
              size={20}
            />
          </a>
        }
        <a onClick={() => abrirDocumento(documento.arquivo)}>
          <MdOpenInNew
            color={colors.halfOpacityBlack}
            size={20}
          />
        </a>
      </OptionsContainer>
    </Container>
  )
}


export default DocItem;