import React from 'react';
import { ButtonComponent } from './styles';
import { IButtonProps } from '../../@interfaces';
import Loader from "react-loader-spinner";
import { useTheme } from 'styled-components';

const Button: React.FC<IButtonProps> = (props) => {
  const theme = useTheme()
  return (
    <ButtonComponent {...props} disabled={props.loading}>
      {
        !props.loading ? props.children : (
          <>
            {/**@ts-ignore */}
            <Loader
              type="TailSpin"
              color={props.loadingColor ? props.loadingColor : theme.colors.white}
              height={15}
              width={50}
            />
          </>
        )
      }
    </ButtonComponent>
  )
}

export default Button;