import React, { useContext, useEffect, useMemo } from "react";

import {
  Route,
  Switch,
  Redirect,
  HashRouter,
  useRouteMatch,
  useHistory
} from "react-router-dom";


import Home from "../pages/home";
import AccessControl from "../pages/accesscontrol";
import AppLayout from "../components/applayout"
import { MenuContextProvider } from "../contexts/menu";
import AuthContext from '../contexts/auth';
import Financeiro from "../pages/faturaseboletos";
import Ronda from "../pages/rondas"
import OcorrenciaDetalhe from "../pages/rondas/ocorrenciadetalhe"

import Funcionarios from "../pages/funcionarios"
import PasswordChange from "../pages/login/passwordchange";
import Ponto from "../pages/ponto";
import Documentos from "../pages/documentos";
import QuadrodeVagas from "../pages/quadrodevagas";
import Noticias from "../pages/noticias";



const Rotas: React.FC = () => {
  const { isMasterUser, acessos, validaAcessos } = useContext(AuthContext);




  const _isMaster = isMasterUser()

  /**
   * RECURSO    DESCRICAO
19334    Rondas
19335    Faturas e Boletos
19336    Ponto
19337    Funcionários
19338    Documentos
19339    Quadro de Vagas
   */


  if (!validaAcessos) {
    return (
      <MenuContextProvider>
        <AppLayout>
          <Switch>
            <Route path={"/"} exact component={Noticias} />
            <Route path={"/rondas"} exact component={Ronda} />

            {_isMaster && <Route path={"/usuarios"} exact component={AccessControl} />}

            <Route path={"/financeiro"} exact component={Financeiro} />
            <Route path={"/rondas"} exact component={Ronda} />
            <Route path={"/ponto"} exact component={Ponto} />
            <Route path={"/ocorrenciadetalhe"} exact component={OcorrenciaDetalhe} />
            <Route path={"/trocadesenha/:id"} exact component={PasswordChange} />
            <Route path={"/funcionarios"} exact component={Funcionarios} />
            <Route path={"/documentos"} exact component={Documentos} />
            <Route path={"/quadro"} exact component={QuadrodeVagas} />
            <Route path={"/ocorrenciadetalhe"} exact component={OcorrenciaDetalhe} />
            <Route path={"/trocadesenha/:id"} exact component={PasswordChange} />

            <Route path={"*"}>
              <Redirect to="/" />
            </Route>

          </Switch>
        </AppLayout>
      </MenuContextProvider>
    )
  } else {
    return (
      <MenuContextProvider>
        <AppLayout>
          <Switch>
            <Route path={"/"} exact component={Noticias} />
            {acessos.includes(19334) && <Route path={"/rondas"} exact component={Ronda} />}

            {_isMaster && <Route path={"/usuarios"} exact component={AccessControl} />}

            {acessos.includes(19335) && <Route path={"/financeiro"} exact component={Financeiro} />}

            {acessos.includes(19336) && <Route path={"/ponto"} exact component={Ponto} />}

            {acessos.includes(19334) && <Route path={"/ocorrenciadetalhe"} exact component={OcorrenciaDetalhe} />}


            {acessos.includes(19337) && <Route path={"/funcionarios"} exact component={Funcionarios} />}


            {acessos.includes(19338) && <Route path={"/documentos"} exact component={Documentos} />}


            {acessos.includes(19339) && <Route path={"/quadro"} exact component={QuadrodeVagas} />}

            <Route path={"/ocorrenciadetalhe"} exact component={OcorrenciaDetalhe} />

            <Route path={"/trocadesenha/:id"} exact component={PasswordChange} />

            <Route path={"*"}>
              <Redirect to={`/`} />
            </Route>

          </Switch>
        </AppLayout>
      </MenuContextProvider>
    )
  }


}

export default Rotas;