import { Container, FieldsContainer, FormLabel, FormRow, Error, SubmitButton, Title, Content } from "./style";
import Input from "./../../../components/input";
import Checkbox from "../../../components/checkbox";
import SelectionGroup from "../../../components/selectiongroup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import { Client, User } from "../../../@types";
import { IUserFormProps, INewUser, IUpdatedUser, ICustumers } from "../../../@interfaces";
import aux from '../../../utils/auxiliar';
import APIUser from "../../../services/APIUser";
import Loader from "react-loader-spinner";
import BaseModal from "../../../components/basemodal";
import ToastContext from "../../../contexts/toast";
import Utils from "../../../utils/auxiliar"
import { useTheme } from "styled-components";


const UserForm: React.FC<IUserFormProps> = ({ userToUpdate, reloadList, closeModal }) => {
  const { register, handleSubmit, reset, formState: { errors, isSubmitSuccessful } } = useForm();
  const [clientList, setClientList] = useState<ICustumers[]>([]);
  const [isClientListLoading, setIsClientListLoading] = useState<boolean>(false);
  const [isMasterUserChecked, setIsMasterUserChecked] = useState<boolean>(!!userToUpdate?.master);
  const [selectedClients, setSelectedClients] = useState<ICustumers[]>([]);
  const [clientError, setClientError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { showErrorToast, showSuccessToast } = useContext(ToastContext);

  const theme = useTheme()


  useEffect(() => {
    loadClientList();
  }, [])


  useEffect(() => {
    reset({});
  }, [isSubmitSuccessful])




  async function loadClientList() {
    try {
      setIsClientListLoading(true);

      const response = await APIUser.getClientList();

      if (response.error || !response.clientes) {
        return;
      }

      setClientList(response.clientes);

      if (userToUpdate) {
        const clientIds = userToUpdate.clientes.map(item => item.id);
        setSelectedClients(response.clientes.filter((item: Client) => clientIds.includes(item.codigo)));
      } else {
        setSelectedClients([response.clientes[0]]);
      }

    } catch {
      return;
    } finally {
      setIsClientListLoading(false);
    }
  }




  function handleCheck() {
    setIsMasterUserChecked(!isMasterUserChecked);
  }




  function handleClientSelection(id: string | number, checked: boolean) {
    const client = clientList.find(item => item.codigo === id);
    if (checked && client) {
      setSelectedClients([...selectedClients, client]);
      setClientError(false);
    } else if (!checked && client) {
      setSelectedClients(selectedClients.filter(item => item.codigo !== id))
    }
  }




  async function addNewUser(newUser: INewUser) {
    try {
      setIsSubmitting(true);
      const response = await APIUser.addNewUser(newUser);

      if (response.err) {
        showErrorToast(response.message);
        return;
      }

      showSuccessToast(response.message);
      reloadList();

    } catch {
      return;
    } finally {
      setIsSubmitting(false)
    }
  }




  async function updateUser(updatedUser: IUpdatedUser) {
    try {
      setIsSubmitting(true);
      const response = await APIUser.updateUser(updatedUser);

      if (response.err) {
        showErrorToast(response.message);
        return;
      }

      showSuccessToast(response.message);
      reloadList();

    } catch {
      return;
    } finally {
      setIsSubmitting(false)
    }
  }




  const onSubmit: SubmitHandler<INewUser> = async data => {
    if (selectedClients.length === 0) {
      setClientError(true);
      return;
    }

    if (userToUpdate) {
      let updatedUser: IUpdatedUser = {
        usuario: userToUpdate.codigo
      }
      for (const param in data) {
        if (data[param as keyof INewUser] !== userToUpdate[param as keyof User]) {
          updatedUser = { ...updatedUser, [param]: data[param as keyof INewUser] };
        }
      }

      let includedClients = selectedClients.filter(item => {
        for (const client of userToUpdate.clientes) {
          if (client.id === item.codigo) {
            return false;
          }
        }

        return true;
      });

      let excludedClients = userToUpdate.clientes.filter(item => {
        for (const selectedClient of selectedClients) {
          if (selectedClient.codigo === item.id) {
            return false;
          }
        }

        return true;
      })


      if (includedClients.length > 0) {
        updatedUser.incluirClientes = includedClients.map(item => {
          return { cliente: item.codigo }
        })
      }

      if (excludedClients.length > 0) {
        updatedUser.excluirClientes = excludedClients.map(item => {
          return { cliente: item.id }
        })
      }




      if (userToUpdate.master !== isMasterUserChecked) {
        updatedUser = { ...updatedUser, master: isMasterUserChecked }
      }


      await updateUser(updatedUser);
    } else {
      const newUser = {
        ...data,
        master: isMasterUserChecked ? 1 : 0,
        clientes: selectedClients.map(item => ({ cliente: item.codigo }))
      }
      await addNewUser(newUser)
    }

    closeModal();

  };




  return (
    <BaseModal closeModal={closeModal} width={'85%'}>
      <Content>
        {/**@ts-ignore */}
        <Container onSubmit={handleSubmit(onSubmit)}>
          <FieldsContainer>
            <FormLabel>
              Nome
              <Input
                register={() => register('nome', { required: true })}
                hasBackground
                colorOfBorder={errors.nome ? theme.colors.red : theme.colors.gray}
                defaultValue={userToUpdate?.nome}
              />
              {errors.nome && <Error>Preencha o nome</Error>}
            </FormLabel>
            <FormLabel>
              Email
              <Input
                register={() => register('email', { required: true, validate: value => aux.validEmailFormat(value) })}
                hasBackground
                colorOfBorder={errors.email ? theme.colors.red : theme.colors.gray}
                defaultValue={userToUpdate?.email}
              />
              {errors.email && <Error>Preencha o e-mail</Error>}

            </FormLabel>




            <FormRow template="1fr 1fr 4fr 1fr">
              <FormLabel >
                DDI
                <Input
                  register={() => register('ddi', { required: false })}
                  onKeyPress={Utils.onlyInputNumber}
                  hasBackground
                  colorOfBorder={errors.ddi ? theme.colors.red : theme.colors.gray}
                  defaultValue={userToUpdate?.ddi}
                  maxLength={3}
                />
              </FormLabel>

              <FormLabel>
                DDD
                <Input
                  register={() => register('ddd', { required: true })}
                  onKeyPress={Utils.onlyInputNumber}
                  maxLength={2}
                  hasBackground
                  colorOfBorder={errors.ddd ? theme.colors.red : theme.colors.gray}
                  defaultValue={userToUpdate?.ddd}
                />
                {errors.ddd && <Error>Informe o ddd</Error>}

              </FormLabel>

              <FormLabel>
                Telefone
                <Input
                  register={() => register('telefone', { required: true })}
                  type="text"
                  maxLength={9}
                  onKeyPress={Utils.onlyInputNumber}
                  hasBackground
                  colorOfBorder={errors.telefone ? theme.colors.red : theme.colors.gray}
                  defaultValue={userToUpdate?.telefone}
                />
                {errors.telefone && <Error>Informe o telefone</Error>}

              </FormLabel>

              <FormLabel>
                Ramal
                <Input
                  maxLength={4}
                  register={() => register('ramal')}
                  hasBackground
                  colorOfBorder={errors.ramal ? theme.colors.red : theme.colors.gray}
                  defaultValue={userToUpdate?.ramal}
                  onKeyPress={Utils.onlyInputNumber}
                />

              </FormLabel>
            </FormRow>


            <div>
              <Checkbox onCheck={handleCheck} label="Usuário master" checked={isMasterUserChecked} />
            </div>

          </FieldsContainer>

          <SelectionGroup
            options={clientList.map(item => ({ value: item.codigo, label: item.nome }))}
            onSelect={handleClientSelection}
            precheckedItems={selectedClients.map(item => item.codigo)}
            error={clientError}
            isLoading={isClientListLoading}
          />

          <SubmitButton disabled={isSubmitting}>
            {isSubmitting ? (
              <>
                {/**@ts-ignore */}
                <Loader type="TailSpin" color={theme.colors.white} height={15} />
              </>
            )
              :
              'Salvar'}
          </SubmitButton>
        </Container>
      </Content>
    </BaseModal>
  )
}

export default UserForm;