
import React, { useEffect, useState, useContext } from 'react';


import { Bar } from 'react-chartjs-2';
import BoxContainer from '../../../components/boxcontainer';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement
} from 'chart.js';

import ModalFilters, { ContentFilter, RowSection, SeparatorFilter } from '../../../components/modalfilters';
import InputDate, { } from '../../../components/inputdate';
import { CustumerContext } from '../../../contexts/custumer';
import moment from "moment"
import ToastContext from '../../../contexts/toast';
import { Container } from './styles';
import APIRondas from '../../../services/APIRondas';
import { IBodyRelacaoRelacaoRondaProgramadaConcluida } from '../../../@interfaces';
import LabelEmptyData from '../../../components/labelemptydata';
import { useTheme } from 'styled-components';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement
);



const optionsChart = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false,
      text: 'Gráfico de eficiência das rondas',
    },
  },
};


const Eficiencia: React.FC = () => {
  const nowIni = moment().subtract(11, "months");
  const nowFim = moment();
  const _nowFim = nowFim.month() + 1;
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [initDate, setInitDate] = useState<string>(`${nowIni.year()}-${nowIni.month().toString().padStart(2, "0")}`);
  const [finalDate, setFinalDate] = useState<string>(`${nowFim.year()}-${_nowFim.toString().padStart(2, "0")}`);
  const { id_current_custumer } = useContext(CustumerContext);
  const [dataChart, setDataChart] = useState<any>(null);
  const { showErrorToast } = useContext(ToastContext);
  const [loading, setLoading] = useState<boolean>(false);

  const theme = useTheme();


  useEffect(() => {
    if (id_current_custumer > 0)
      getData();
  }, [id_current_custumer]);





  function openFilter() {
    setShowFilters(true);
  }




  function closeFilter() {
    setShowFilters(false)
  }




  function handleDateIni(e: React.FormEvent<HTMLInputElement>) {

    setInitDate(e.currentTarget.value)
  }




  function handleDateFim(e: React.FormEvent<HTMLInputElement>) {
    setFinalDate(e.currentTarget.value);
  }




  async function getData() {
    try {
      setLoading(true);
      const body: IBodyRelacaoRelacaoRondaProgramadaConcluida = {
        cliente: id_current_custumer,
        anoMesIncial: getDate("ini"),
        anoMesFinal: getDate("fim"),
      }


      const response = await APIRondas.getRelacaoRondaProgramadaConcluida(body);

      setLoading(false);

      if (response.error) {
        showErrorToast(response.mensagem ? response.mensagem : "Não foi possível realizar filtro");
        return;
      }

      const labels: string[] = [];
      const concluidas: number[] = [];
      const programadas: number[] = [];

      if (response.listaQtdeRondas?.length === 0) {
        setDataChart(null);
        return;
      }

      response.listaQtdeRondas?.map(i => {
        labels.push(`${i.mes}/${i.ano}`);
        concluidas.push(i.concluidas)
        programadas.push(i.programadas)
      });



      const objForChart = {
        labels: labels,
        datasets: [
          {
            label: 'Programadas',
            data: programadas,
            borderColor: theme.colors.pink,
            backgroundColor: theme.colors.lightPink
          },
          {
            label: 'Concluído',
            data: concluidas,
            borderColor: theme.colors.blue,
            backgroundColor: theme.colors.primary,
          }
        ],
      }

      setDataChart(objForChart)

    } catch (error) {
      console.log(error);
      setLoading(false);
      throw new Error("getDataEficiencia: " + error)
    }
  }




  function applyFilter() {
    if (moment(initDate).isAfter(moment(finalDate))) {
      showErrorToast("Data inicial posterior a data final");
    }
    closeFilter();
    getData();
  }




  function getDate(type: "ini" | "fim"): string {
    return {
      ini: () => {
        const date = initDate;
        const result = date.split("-");
        return `${result[0]}${result[1]}`
      },
      fim: () => {
        const date = finalDate;
        const result = date.split("-");
        return `${result[0]}${result[1]}`
      }
    }[type]();
  }





  return (
    <>
      {showFilters && (
        <ModalFilters handleClose={closeFilter} cleanFilter={() => { }} applyFilter={applyFilter}>
          <ContentFilter>
            <RowSection>
              <InputDate
                value={initDate}
                onChange={handleDateIni}
                label="Início"
                type={"month"}
                inputStyle={{ width: 180 }}
                containerStyle={{ width: 190 }}
              />

              <InputDate
                value={finalDate}
                onChange={handleDateFim}
                label="Fim"
                type={"month"}
                inputStyle={{ width: 180 }}
                containerStyle={{ width: 190 }}
              />
            </RowSection>

            <SeparatorFilter />
          </ContentFilter>
        </ModalFilters>
      )}
      <Container>
        <BoxContainer
          title='Eficiência de ronda'
          loading={loading}
          gridArea={'eficiencia'}
        >
          <div style={{ maxWidth: "98%" }}>
            {dataChart && <Bar options={optionsChart} data={dataChart} />}
            {!dataChart && <LabelEmptyData>Nenhum dado a ser exibido</LabelEmptyData>}
          </div>

        </BoxContainer>
      </Container>
    </>
  )
}

export default Eficiencia;