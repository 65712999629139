import React from 'react';

import { Container } from './styles';

type DateTypeProps = "date" | "dateTyme" | "month"

interface IProps {
  dateIni?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  type?: DateTypeProps;
  containerStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  strColor?: string;
  label?: string;
  value: any;
  max?: string,
  min?: string
}

const InputDate: React.FC<IProps> = ({ dateIni, onChange, type, containerStyle = {}, inputStyle = {}, strColor, label, value, max, min }) => {
  return (
    <Container style={containerStyle} strColor={strColor}>
      {label && <label>{label}</label>}
      <input type={type ? type : "date"} value={value} onChange={onChange} style={inputStyle} max={max || "9999-12-31"} min={min}/>
    </Container>
  )
}

export default InputDate;          
