import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { IAgrupamentoDocumento, IDocumento, IEmpresa, ITipoDocumento } from "../../../../@interfaces";
import { SelectOptions } from "../../../../@types";
import { CustumerContext } from "../../../../contexts/custumer";
import ToastContext from "../../../../contexts/toast";
import APIArquivos from "../../../../services/APIArquivos";
import APIDocumentos from "../../../../services/APIDocumentos";
import APIFuncionario from "../../../../services/APIFuncionario";
import APIPonto from "../../../../services/APIPonto";
import { default as Utils, default as aux } from "../../../../utils/auxiliar";
import { FiltrosFuncionario as Filtros, TipoDocumentoOpcoes} from "../../../../@types/documentos";
import { IDocsProps, IDocumentosFuncionarioBody } from "../../../../@interfaces/docFuncionario";



type FiltrosComDropdown = Omit<Filtros, 'dataInicial' | 'dataFinal'>

export default function useDocs(props: IDocsProps){
  const { startDate, finalDate, employeeId, localPrevio } = props;
	
	
  const filtrosValoresIniciais: Filtros = {
    dataInicial: startDate,
    dataFinal: finalDate,
    alocacao: 'tafTodos',
    assinatura: 'tacTodos',
    tipoDocumento: null,
  }

  
  const [filtrosAtuais, setFiltrosAtuais] = useState<Filtros | null>(filtrosValoresIniciais);

  const [filtroAberto, setFiltroAberto] = useState<keyof FiltrosComDropdown|null>(null);

  const { colors } = useTheme();
  const [listaDocumento, setListaDocumento] = useState<IDocumento[]>([]);
  const [documentosSelecionados, setDocumentosSelecionados] = useState<number[]>([]);
  const [todosSelecionados, setTodosSelecionados] = useState<boolean>(false);

  const [carregando, setCarregando] = useState<boolean>(false);
  const [acaoCarregando, setAcaoCarregando] = useState<string | null>(null);


  const [tiposDocumento, setTiposDocumento] = useState<TipoDocumentoOpcoes[]>();



  const [documentoAberto, setDocumentoAberto] = useState<string>();
  const [modalDocumentoAberto, setModalDocumentoAberto] = useState<boolean>(false);



  const [empresasDisponiveis, setEmpresasDisponiveis] = useState<IEmpresa[]>([]);
  const [empresaSelecionada, setEmpresaSelecionada] = useState<number>(0);

  const { showErrorToast } = useContext(ToastContext);
  const { id_current_custumer } = useContext(CustumerContext);



  const tiposFixos: TipoDocumentoOpcoes[] = [
    { value: 0, label: "Todos"},
    { value: 1, label: "FGTS", grupoFiltros: 'empresa' },
    { value: 2, label: "GPS", grupoFiltros: 'empresa' },
    { value: 3, label: "Dtcf Web", grupoFiltros: 'empresa' },
    { value: 4, label: "Protocolo FGTS", grupoFiltros: 'empresa' },

    { value: 5, label: "Folha de Pagamento", grupoFiltros: 'cliente' },
    { value: 6, label: "SEFIP", grupoFiltros: 'cliente' },
    { value: 7, label: "Darf", grupoFiltros: 'cliente' },
    { value: 8, label: "GPS Cliente", grupoFiltros: 'cliente' },
  ]


  const alocacoes = [
    {value: "tafTodos", label: "Todos"},
    {value: "tafEfetivo", label: "Efetivo"},
    {value: "tafTemporario", label: "Temporário"},
  ]


  const statusAssinatura = [
    {value: "tacTodos", label: "Todos"},
    {value: "tacAssinado", label: "Assinado"},
    {value: "tacNaoAssinado", label: "Não assinado"},
  ]



  useEffect(() => {
    carregarTiposDocumento();
  }, [])




  useEffect(() => {
    const novosValores: Filtros = {...filtrosValoresIniciais};
    
    setListaDocumento([]);
    setEmpresasDisponiveis([]);
    setFiltrosAtuais({...novosValores});

  }, [id_current_custumer]);
 




  function selecionarEmpresa(empresa: number){
    if(empresa === empresaSelecionada && empresasDisponiveis.length > 1) return setEmpresaSelecionada(0);
    setEmpresaSelecionada(empresa);
  }




  async function carregarTiposDocumento() {
    try {
      const response = await APIFuncionario.getTiposDocumento();

      if (!response.error) {
        const formattedDocTypes = formatarTiposDocumento(response.tiposDocumentos!);
        setTiposDocumento(formattedDocTypes);
      } else {

      }
    } catch {} 
  }




  function formatarTiposDocumento(list: ITipoDocumento[]): SelectOptions[] {
    const formattedList = list.map(item => {
      return { value: item.tipo, label: item.nome }
    })

    return formattedList;
  }




  async function carregarDocumentos() {
    setEmpresasDisponiveis([]);

    setCarregando(true);

    try {
      // if(!docFuncionario && !docTypeValue) return;

      setListaDocumento([]);

      const { dataInicial, dataFinal, alocacao, assinatura, tipoDocumento } = filtrosAtuais!;

      const bodies: IDocumentosFuncionarioBody[] = [
        {
          cartaoPonto: {
            funcionario: employeeId!,
            dataInicio: moment(dataInicial).format("YYYYMMDD"),
            dataFinal: moment(dataFinal).format("YYYYMMDD"),
            cliente: id_current_custumer,
            local: localPrevio!,
            tipoAlocacao: alocacao!,
            tipoAssinatura: assinatura!
          }
        },
        {
          demonstrativoPontoCliente: {
            funcionario: employeeId!,
            dataInicio: moment(dataInicial).format("YYYYMMDD"),
            dataFinal: moment(dataFinal).format("YYYYMMDD"),
            cliente: id_current_custumer,
            local: localPrevio!,
          }
        }
      ]



      const response = await APIFuncionario.getDocumentos(bodies[tipoDocumento! - 1]);



      if (!response.error) {
        let auxResposta = response.documentos?.map(documento => {
          return {...documento, rotuloTipo: tiposFixos.find(tipo => tipo.value === filtrosAtuais?.tipoDocumento)?.label}
        })

        setListaDocumento(auxResposta!);
      } 

    } catch {
      showErrorToast("Erro ao carregar a lista de documentos")
    } finally {
      setCarregando(false);

    }
  }



  

  function marcarDocumento(id: number, checked: boolean) {
    if (checked) setDocumentosSelecionados([...documentosSelecionados, id]);
    else setDocumentosSelecionados(documentosSelecionados.filter(item => item !== id));

  }




  function marcarTodosDocumentos(checked: boolean) {
    setTodosSelecionados(checked);
    if (checked){
      let selecionados: number[] = [];

      selecionados = listaAMostrar.map(documento => documento.arquivo);
      

      setDocumentosSelecionados(selecionados);
    }
    else setDocumentosSelecionados([]);
  }


  


  async function carregarArquivo(id: number) {
    try {

      const response = await APIArquivos.getArquivo({ Arquivos: [id] })

      if (!response.error) {
        return response.listaArquivo![0]!.conteudo;
      }

      return false;

    } catch {
      return false;
    }
  }




  async function abrirDocumento(id: number) {
    setModalDocumentoAberto(true);
    const file = await carregarArquivo(id);

    if (file) setDocumentoAberto(file);
    else showErrorToast('Erro ao abrir o documento.');
  }




  function fecharDocumento() {
    setModalDocumentoAberto(false);
    setDocumentoAberto('');
  }




  async function baixarArquivo(id: number) {
    setAcaoCarregando(String(id))
    const file = await carregarArquivo(id);

    const fileObj = listaDocumento?.find(item => item.arquivo === id);
    const name = fileObj?.descricaoArquivo;
    if (file) aux.downloadFile(file, `${name}.pdf`);
    else showErrorToast('Erro ao fazer o download do documento.');

    setAcaoCarregando(null);
  }




  async function baixarArquivosSelecionados() {
    setDocumentosSelecionados([]);
    setTodosSelecionados(false);

    for (const docId of documentosSelecionados) {
      await baixarArquivo(docId);
    }

  }




  function atualizarValoresFiltros(value: Filtros[keyof Filtros], filter: keyof Filtros){
   
    const novosValores = {...filtrosAtuais!, [filter]: value};
    setFiltrosAtuais({...novosValores});
  }




  function abrirOpcoes(opcao: keyof FiltrosComDropdown){
    if(!carregando) setFiltroAberto(opcao);
  }




  function fecharOpcoes(){
    setFiltroAberto(null);
  }




  function agruparLista(lista: IDocumento[]){
    let listaAgrupada: IAgrupamentoDocumento[] = [];

    for(const documento of lista){
      if(!listaAgrupada.find(grupo => grupo.tipo === documento.rotuloTipo)){
        listaAgrupada = [...listaAgrupada, {tipo: documento.rotuloTipo!, documentos: [documento]}]
      }else{
        listaAgrupada = listaAgrupada.map(grupo => {
          return grupo.tipo === documento.rotuloTipo ? 
          {...grupo, documentos: [...grupo.documentos, documento]}
          : grupo
        })
      }
    }

    return listaAgrupada;
  }



  const concantenarNome = tiposFixos.find(item => item.value === filtrosAtuais?.tipoDocumento)?.grupoFiltros === 'cliente';

  const mostrarLista = listaDocumento.length > 0;

  const listaAMostrar = empresaSelecionada ? 
    listaDocumento.filter(documento => documento.relEmpresa === empresaSelecionada) : listaDocumento;
  


  const listaAgrupada: IAgrupamentoDocumento[] = agruparLista(listaAMostrar);

  const tipoSelecionado = !!tiposDocumento ? tiposDocumento!.find(item => item.value === filtrosAtuais!.tipoDocumento): null;


  const opcoes = {
    tipoDocumento: tiposDocumento,
    alocacao: alocacoes,
    assinatura: statusAssinatura
  }


  const buscaDisponivel = !!filtrosAtuais?.tipoDocumento;

  return {
    buscaDisponivel,
    filtrosAtuais,
    filtroAberto,
    colors,
    todosSelecionados,
    carregando,
    acaoCarregando,
    documentoAberto,
    modalDocumentoAberto,
    empresasDisponiveis,
    empresaSelecionada,
    alocacoes,
    statusAssinatura,
    documentosSelecionados,
    tiposDocumento,
    tipoSelecionado,
    concantenarNome,
    mostrarLista,
    listaAgrupada,
    opcoes,
    carregarDocumentos,
    selecionarEmpresa,
    abrirDocumento,
    fecharDocumento,
    baixarArquivo,
    baixarArquivosSelecionados,
    atualizarValoresFiltros,
    abrirOpcoes,
    fecharOpcoes,
    marcarDocumento,
    marcarTodosDocumentos,
    
  }
 
}