import styled, { css } from "styled-components";
import Button from "../../components/button";


interface IButtonProps {
  loading: boolean;
}


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Row = styled.div`
  width: 100%;
  display: flex; 
  position: relative;
`

export const FilterContainer = styled.div`
  width: 100%;
  padding: 15px 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  position: sticky;
`

///////////AQUI
export const ContainerInput = styled.div` 
  margin-right: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  >label{
    color: rgba(0,0,0,.4);
    font-family: ${props => props.theme.font};
    font-size:.875rem;
    font-weight: 500;
  }
`

const inputStyle = css`
	border: none;
	border-radius: 8px;
	background: #fff;
	padding: 7px 15px;
	width: 145px;
	display: flex;
	justify-content: space-between;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	outline: none;
	color: rgba(0,0,0,.5);
`


export const InputDate = styled.input`
  ${inputStyle}
`


export const ButtonSearch = styled(Button) <IButtonProps>`
  border: none;
  padding: 7px 8px;
  width: 200px;
  background: ${props => props.theme.colors.blue};
  border-radius: 4px;
  color: ${props => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .75rem;
  cursor: pointer;

  transition: .4s all;

  ${({ loading }) => {
    if (loading) return `background: #fff`
  }}
`

interface IDropdownProps {
  width?: number;
}


export const DropDown = styled.div<IDropdownProps>`
  ${inputStyle}
  ${props => props.width ? `width: ${props.width}px;` : ''}
  
  :hover{
		cursor: pointer;
	}

  display: flex;
  align-items: center;

	span{
		color: rgba(0,0,0,.5);
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
    font-size: .75rem;
	}
`