import styled, { css } from "styled-components";

export const Container = styled.div<{ paddingLateral: number}>`
  display: flex;
  flex-direction: column;
  padding: 0 ${props => props.paddingLateral}px;
`


export const FilterContainer = styled.div<{ paddingLateral?: number}>`
  display: flex;
  align-items: flex-end;
  background: #fafafa;
  padding: 20px ${props => props.paddingLateral}px;
  gap: 20px;

  > a, label {
    display: flex;
    align-items: center;
    gap: 20px;
    color: ${props => props.theme.colors.halfOpacityBlack};
    font-family: ${props => props.theme.font};
    font-size:.875rem;
    font-weight: 500;

  }
`


export const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  flex-direction: column;

  div.row {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  span, label {
    font-size: .75rem;
    text-align: center;
  }
`


export const Input = styled.input`
  border: none;
  border-radius: 8px;
  background: #fff;
  padding: 7px 15px;
  width: 175px;
  display: flex;
  justify-content: space-between;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  outline: none;
  color: rgba(0,0,0,.5);
  font-size: .75rem;
`


export const DocList = styled.ul`
  max-height: 100vh;
  overflow-y: auto;

  padding-right: 5px;
  ::-webkit-scrollbar {
    width: 5px;
  }
  
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 3px solid ${props => props.theme.colors.gray};
  }

  li {
    display: flex;
    padding: 6px 0;
    justify-content: space-between;
    align-items: center;

    :hover{
      background: ${props => props.theme.colors.lightGray};
    }
  }
`

export const CheckOption = styled.div`
  display: flex;
  gap: 50px;
  align-items: center;

  
`

export const DownloadSelectedFilesButton = styled.button`
  border: none;
  background: none;
  display: flex;
  gap: 5px;
  align-items: center;

  :hover{
    cursor: pointer;
  }

  span {
    font-size: .9rem; 
    font-weight: bold;
  }
`

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  span {
    color: ${props => props.theme.colors.black};
  }

`

export const CheckLabel = styled.label`
  font-weight: bold;
  font-size: .875rem;
  color: ${props => props.theme.colors.halfOpacityBlack};

  
`


export const LinhaSkeleton = styled.div`
  display: grid;
  gap: 4px;
  padding: 10px 0;
`


export const OptionsContainer = styled.div`
  display: flex;
  gap: 20px;

  a {
    :hover {
      cursor: pointer;
    }
  }
`


export const FilterMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  color: ${props => props.theme.colors.black};
  font-size: .875rem;
  align-self: center;
  letter-spacing: 1px;
  margin-top: 20px;
  font-weight: bold;

  span:last-child {
    font-weight: 300;
  }
`

interface IDropdownProps{
  width?: number;
}



const inputStyle = css`
	border: none;
	border-radius: 8px;
	background: #fff;
	padding: 7px 15px;
	width: 145px;
  height: 30px;
	display: flex;
	justify-content: space-between;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	outline: none;
	color: rgba(0,0,0,.5);
`



export const DropDown = styled.div<IDropdownProps>`
  ${inputStyle}
  ${props => props.width? `width: ${props.width}px;`:''}
  
  :hover{
		cursor: pointer;
	}

  display: flex;
  align-items: center;

	span{
		color: rgba(0,0,0,.5);
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
    font-size: .75rem;
	}
`


export const InputDate = styled.input<IDropdownProps>`
  ${inputStyle}
  ${props => props.width ? `width: ${props.width}px`: 'width: 175px'};
`


export const EmpresasContainer = styled.div`
  display: flex;
  gap: 15px;
  padding: 10px 0;
`


export const BotaoEmpresa = styled.button<{ selecionado?: boolean}>`
  border: none;
  background: #fff;

  padding: 10px 15px;
  border-radius: 12px;
  box-shadow: -2px -2px 10px -7px rgba(0,0,0,0.75);
  cursor: pointer;

  ${props => props.selecionado && `
    background: ${props.theme.colors.darkSilver};
    color: #fff;
  `}

`


export const TituloAgrupamento = styled.h1`
  margin: 20px 0;
`

export const ContainerSelecao = styled.div<{ selecionarTodos?: boolean}>`
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  ${props => props.selecionarTodos && `background: ${props.theme.colors.halfOpacityBlack}`}
`