import styled from "styled-components";

interface IContentProps {
  hasImage: boolean
}

export const Card = styled.div`
  width: 100%;
  border-radius: 6px;
  background: ${props => props.theme.colors.white};
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  margin-bottom: 32px;

  .itemCarousel{
    margin: 0 6px 6px;
    padding: 0px;

  }

  .containerCarousel{
    
  }
  .itemClass{

  }
`


export const CarouselContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 16px;
  border-top: 1px solid #eee;
  >h2{
    font-size: 14px;
    margin-bottom: 16px;
    color: #999;
  }
`


export const ContainerPdfs = styled.div`
  width: 100%; 
  padding: 16px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  border-top: 1px solid #eee;
  >h2{
    font-size: 14px;
    margin-bottom: 16px;
    color: #999;
  }
  
`


export const BoxImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  
  &:hover{    
    transition: all .3s;
  }
`

export const ImageSlide = styled.img`
  width: 100%;

`

export const Header = styled.div`
  width: 100%;
  border-bottom: 1px solid #eee;
  padding: 10px;

  >h1{
    margin-bottom: 6px;    
    font-size: 15px;
  }

  >span{
    font-size: 13px;
    color: #999
  }

`

export const ContentImage = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
`

export const Content = styled.div<IContentProps>`
  width: 100%;
  padding: 12px 16px 0px;
  display: flex;
  flex-direction: column;

  ${({ hasImage }) => {
    if (!hasImage) {
      return `padding: 12px 16px 12px;`
    }
  }}
  overflow: hidden;
  >p{
    font-size: 15px;
    line-height: 22.5px;
  }
  .linkIntoText{
    color: ${props => props.theme.colors.primary}
  }

  .btn_visibleMais{
    display: block;
    font-weight: 800;
    margin-top: 8px;
    cursor: pointer;
    &:hover{
      text-decoration-line: underline;
      
    }
  }
`