import styled, { keyframes } from "styled-components";


interface IProps {
  isOpen?: boolean
}

const Anima = keyframes`
  from{
    opacity: 0
  }to{
    opacity: 1;
  }
`


export const Version = styled.span`
  position: absolute;
  bottom: 10px;
  text-align: center;
  align-self: center;
  font-size: .69rem;
  color: ${props => props.theme.colors.white};
`



export const Container = styled.div<IProps>`
  grid-area: aside;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${props => props.theme.colors.darkSilver};
  transition: all .3s;
  width: 200px;
  @media(max-width: 700px){
    position: absolute;
    left: 0;
    z-index: 1002;
    height: 100vh;
  }
`


export const CompanyContainer = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; 
  padding: 18px 15px;
  animation: ${Anima} 2s  forwards;
  >span{
    color: #fff;
    font-size: .69rem;
    margin-top: 8px;
  }
`

export const LogoContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;

 
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  justify-content: center;
  align-items: center; 
  overflow: hidden;
  >img{
    width: 100%;
    height: 100%;
  }
`

export const CompanyName = styled.h1<IProps>`
  
  font-weight: 700;
  font-size: 1rem;
  color:${props => props.theme.colors.white};
  margin-top: 10px;
  display: block;
  text-align: center;
  line-height: 1.2rem;

`

export const MenuList = styled.ul``